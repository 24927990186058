:root {
  --border-opacity: 1;
  --text: #3d4b69;
  --body: #f7f9fe;
  --white: #ffffff;
  --font-body: "Inter";
  --font-title: "Lexend";
  --primary: #1965ff;
  --gray: #f7f9fe;
  --sky: #0EA5E9;
  --rose: #F43F5E;
  --primary-active: #004ce5;
}

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  -moz-tab-size: 4;
  tab-size: 4;
  background-color: var(--body);
  color: var(--text);
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

body {
  font-family: inherit;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo,
    monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  font-family: var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

input:focus {
  outline: none;
}

button,
[role="button"] {
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-title), var(--font-body), serif;
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

pre,
code,
kbd,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*,
::before,
::after {
  --border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--border-opacity));
}

.form-control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.form-control:focus-within {
  color: var(--primary);
}

input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  color: currentColor;
  width: 1.15rem;
  height: 1.15rem;
  border: 0.15rem solid currentColor;
  border-radius: 50%;
  /* transform: translateY(-0.075em); */
  /* display: grid; */
  /* place-content: center; */
  position: relative;
}

input[type=radio]:checked {
  border-color: var(--primary);
}

input[type=radio]::before {
  content: "";
  position: absolute;
  inset: 0.15rem;
  margin: auto;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* box-shadow: inset 1em 1em var(--primary); */
  background-color: var(--primary);
}

input[type=radio]:checked::before {
  transform: scale(1);
}

.text-primary {
  color: var(--primary);
}

.text-facebook {
  color: #1877F2;
}

.text-instagram {
  color: #E4405F;
}

.text-twitter {
  color: #1DA1F2;
}

.text-youtube {
  color: #FF0000;
}

.bg-text {
  background-color: var(--text);
}